let initialState = {
  ophanEpPvtRakis49: "",
  ophanEpRakis49: "",
  ophanEpLiquidityReward1: "",
  ophanEpLiquidityReward4: "",
  ophanEpLiquidityV2Reward1: "",
  ophanEpLiquidityV2Reward4: "",
  ophanEpBonusV2: "",
  ophanEpBonusV3: "",
  ophanEpHoldingLaunchMusi: "",
  ophanEpHoldingTransferrewardLaunchMusi: "",
  ophanEpHoldingMusiRewardV2: "",
  ophanEpBonus: "",
  ophanEpBonusOneYear: "",
  ophanEpPvtRakis49OneYear: "",
  ophanEpRakis49OneYear: "",
  ophanEpLiquidityReward1OneYear: "",
  ophanEpLiquidityReward4OneYear: "",
  ophanEpLiquidityV2Reward1OneYear: "",
  ophanEpLiquidityV2Reward4OneYear: "",
  ophanEpBonusV2OneYear: "",
  ophanEpBonusV3OneYear: "",
  ophanEpTransferrewardLaunchMusiOneYear: "",
  ophanEpMusiRewardV2OneYear: "",
};

function opHanEpTokenomicReducer(state = initialState, action) {
  let { type, payload } = action;

  switch (type) {
    case "GET_OP_HANEP_TOKENOMIC":
      return {
        ...state,
        ophanEpPvtRakis49: payload.ophanEpPvtRakis49,
        ophanEpRakis49: payload.ophanEpRakis49,
        ophanEpLiquidityReward1: payload.ophanEpLiquidityReward1,
        ophanEpLiquidityReward4: payload.ophanEpLiquidityReward4,
        ophanEpLiquidityV2Reward1: payload.ophanEpLiquidityV2Reward1,
        ophanEpLiquidityV2Reward4: payload.ophanEpLiquidityV2Reward4,
        ophanEpBonusV2: payload.ophanEpBonusV2,
        ophanEpBonusV3: payload.ophanEpBonusV3,
        ophanEpHoldingLaunchMusi: payload.ophanEpHoldingLaunchMusi,
        ophanEpHoldingTransferrewardLaunchMusi: payload.ophanEpHoldingTransferrewardLaunchMusi,
        ophanEpHoldingMusiRewardV2: payload.ophanEpHoldingMusiRewardV2,
        ophanEpBonus: payload.ophanEpBonus,
        ophanEpBonusOneYear: payload.ophanEpBonusOneYear,
        ophanEpPvtRakis49OneYear: payload.ophanEpPvtRakis49OneYear,
        ophanEpRakis49OneYear: payload.ophanEpRakis49OneYear,
        ophanEpLiquidityReward1OneYear: payload.ophanEpLiquidityReward1OneYear,
        ophanEpLiquidityReward4OneYear: payload.ophanEpLiquidityReward4OneYear,
        ophanEpLiquidityV2Reward1OneYear: payload.ophanEpLiquidityV2Reward1OneYear,
        ophanEpLiquidityV2Reward4OneYear: payload.ophanEpLiquidityV2Reward4OneYear,
        ophanEpBonusV2OneYear: payload.ophanEpBonusV2OneYear,
        ophanEpBonusV3OneYear: payload.ophanEpBonusV3OneYear,
        ophanEpTransferrewardLaunchMusiOneYear: payload.ophanEpTransferrewardLaunchMusiOneYear,
        ophanEpMusiRewardV2OneYear: payload.ophanEpMusiRewardV2OneYear,
      };
    default:
      return { ...state };
  }
}

export default opHanEpTokenomicReducer;
